import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import AppLayout from "src/components/layout/AppLayout";
import AppContext from "src/contexts/AppContext";
import { useDocumentosPedientesFetch } from "src/hooks/useDocumentosPedientesFetch";
import { usePersonasFetch } from "src/hooks/usePersonasFetch";
import { useTiposDocFetch } from "src/hooks/useTiposDocFetch";

const AlertasPage = ()=>{
	const { user } = useContext(AppContext);

	const navigate = useNavigate();

	const {personal} = usePersonasFetch();
	const { docsPendientes, docsPedientesTrabajadores, docsPendientesValidar } = useDocumentosPedientesFetch(user?.id);
	const { tipoDocs } = useTiposDocFetch();

	const trabajadores = useMemo(() => {
		const out = [];
		personal?.forEach((t) => {
			if (docsPedientesTrabajadores?.find((d) => d.pertenece === t.id)) {
				out.push(t);
			}
		});
		return out;
	}, [docsPedientesTrabajadores, personal]);

	const docsPendientesLength = useMemo(() => docsPendientes?.filter((d) => !d.valido)?.length ?? 0, [docsPendientes]);

    return (
			<AppLayout>
				<div class='surface-ground pt-2 md:px-6 lg:px-8'>
					<div class='grid'>
						<div class='col-12 md:col-6 lg:col-6'>
							<div class='surface-card shadow-2 p-3 border-round'>
								<div class='flex justify-content-between mb-3'>
									<div>
										<span class='block text-500 font-medium mb-3'>
											<i class='pi pi-wallet text-blue-500 text-xl mr-3 p-overlay-badge'>
												{docsPendientesLength && <Badge value={docsPendientesLength} severity='danger'></Badge>}
											</i>
											Documentos de Empresa
										</span>
									</div>
								</div>
								<hr class='my-3 mx-0 border-top-1 border-none surface-border' />
								<ul
									class='list-none p-0 m-0 flex-grow-1'
									style={{ maxHeight: 'calc(100vh - 260px)', overflowY: 'auto' }}
								>
									{docsPendientes
										?.filter((d) => !d.valido)
										.map((d) => (
											<li class='flex align-items-center mb-3'>
												<span class='text-900 mr-2'>
													{docsPendientesValidar?.find((dd) => dd.tipo === d.tipoDoc && (!d.validado && !d.rechazado)) ? (
														<i class='pi pi-spin pi-cog text-amber-500 mr-2'></i>
													) : (
														<i class='pi pi-exclamation-triangle text-red-500 mr-2'></i>
													)}
													
													<strong>{tipoDocs?.find((td) => td.id === d.tipoDoc)?.tipo}</strong>
												</span>
												-
												<span class='text-900 ml-2'>
													{/* {docsPendientesValidar?.find((dd) => dd.tipo === d.tipoDoc)
														? ' Pendiente de validar'
														: d.errores} */}
														{d.errores}
												</span>
												<span class='ml-auto'>
													{docsPendientesValidar?.find((dd) => dd.tipo === d.tipoDoc) ? (
														' '
													) : (
														<Button
															icon='pi pi-file-import'
															label='Corregir'
															size='small'
															onClick={() => {
																navigate(`/documentacion`);
															}}
														/>
													)}
												</span>
											</li>
										))}
									{(!docsPendientes || docsPendientes?.filter((d) => !d.valido)?.length === 0) && (
										<li>
											<i class='pi pi-check text-green-500 mr-2'></i>
											<span class='text-900'>Todo Correcto.</span>
										</li>
									)}
								</ul>
							</div>
						</div>
						<div class='col-12 md:col-6 lg:col-6'>
							<div class='surface-card shadow-2 p-3 border-round'>
								<div class='flex justify-content-between mb-3'>
									<div>
										<span class='block text-500 font-medium mb-3'>
											<i class='pi pi-users text-blue-500 text-xl mr-3  p-overlay-badge'>
												{docsPedientesTrabajadores?.length > 0 && (
													<Badge value={docsPedientesTrabajadores?.length} severity='danger'></Badge>
												)}
											</i>
											Documentos de Trabajadores
										</span>
									</div>
								</div>
								<hr class='my-3 mx-0 border-top-1 border-none surface-border' />
								<ul
									class='list-none p-0 m-0 flex-grow-1'
									style={{ maxHeight: 'calc(100vh - 260px)', overflowY: 'auto' }}
								>
									{trabajadores?.map((t) => (
										<li class='flex align-items-center mb-3 '>
											<div class='border-1 surface-border border-round p-3 w-full'>
												<div class='flex align-items-center mb-3'>
													<span className='text-900 font-medium mr-3'>{t.nombre}</span>
													<span class='text-sm font-medium text-500'> </span>
													<span class='ml-auto'>
														<Button
															icon='pi pi-file-import'
															label='Corregir'
															size='small'
															onClick={() => {
																navigate(`/persona/${t.id}`);
															}}
														/>
													</span>
												</div>
												<p class='m-0 p-0 line-height-3 text-600'>
													<ul>
														{docsPedientesTrabajadores
															?.filter((d) => d?.pertenece === t?.id)
															.map((d) => (
																<li>
																	<strong>{tipoDocs?.find((td) => td.id === d.tipoDoc)?.tipo}</strong>
																	- {d.errores}
																</li>
															))}
													</ul>
												</p>
											</div>
										</li>
									))}
									{docsPedientesTrabajadores?.length === 0 && (
										<li>
											<i class='pi pi-check text-green-500 mr-2'></i>
											<span class='text-900'>Todo Correcto.</span>
										</li>
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</AppLayout>
		);
}

export default AlertasPage;