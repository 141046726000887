import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import AppLayout from 'src/components/layout/AppLayout';
import NuevaPersonaDlg from 'src/components/personal/NuevaPersonaDlg';
import { useDocumentosPedientesFetch } from 'src/hooks/useDocumentosPedientesFetch';
import { usePersonasFetch } from 'src/hooks/usePersonasFetch';
import orderBy from 'lodash/orderBy';

const PersonalPage = () => {
	const { personal } = usePersonasFetch();
    const {docsPedientesTrabajadores} = useDocumentosPedientesFetch();
	const [openPersona, setOpenPersona] = useState(null);
	const navigate = useNavigate();

  

	const renderValido = useCallback((row, col) => {
		const value = row[col.field];
		return value ? (
			<i className='pi pi-check' style={{ color: 'green' }}></i>
		) : (
			<i className='pi pi-times' style={{ color: 'red' }}></i>
		);
	}, []);

    const renderMalito = useCallback((row, col) => {
            return <>{
							docsPedientesTrabajadores
								?.filter((d) => d?.pertenecePersona?.id === row?.id && !d?.valido)
								.map((d) => <li>{d.error}</li>)
						}
                        </>;
		}, [docsPedientesTrabajadores]);

	const renderAccionesDocs = useCallback((row) => {
		return (
			<div>
				<Button icon='pi pi-user' rounded outlined tooltip='Ficha personal' onClick={()=>{
					navigate(`/persona/${row.id}`)
				}}/>
			</div>
		);
	}, [navigate]);

	const openPersonaDlg = useCallback(()=>{
		setOpenPersona(true);
	},[]);

	const closePersonaDlg = useCallback(() => {
		setOpenPersona(null);
	}, []);

	return (
		<AppLayout>
			<div class='surface-section px-4 py-5 md:px-6 lg:px-8'>
				<div class='flex align-items-start flex-column lg:justify-content-between lg:flex-row'>
					<div>
						<div class='font-medium text-3xl text-900'>	<i class='pi pi-users mr-2'></i>Documentación de Trabajadores</div>
						<div class='flex align-items-center text-700 flex-wrap'>
							<div class='mr-5 flex align-items-center mt-3'>
								<i class='pi pi-users mr-2'></i>
								<span>{personal?.length ?? 0} Trabajador/es</span>
							</div>
						</div>
					</div>
					<div class='mt-3 lg:mt-0'>
						<Button label='Añadir trabajador' icon='pi pi-plus' onClick={openPersonaDlg} />
					</div>
				</div>
				<div>
					<DataTable value={orderBy(personal, ['habilitado', 'nombre'], 'desc')} stripedRows tableStyle={{ minWidth: '50rem' }}>
						<Column field='nombre' header='Nombre'></Column>
						<Column field='dni' header='Dni'></Column>
						<Column field='habilitado' header='Habilitado' body={renderValido}></Column>
						<Column field='malito' header='Doc. incompleta' body={renderMalito}></Column>
						<Column body={renderAccionesDocs}></Column>
					</DataTable>
					{openPersona && <NuevaPersonaDlg onClose={closePersonaDlg} />}
				</div>
			</div>
		</AppLayout>
	);
};

export default PersonalPage;
