import { Button } from 'primereact/button';
import React from 'react';

const DownloadFileButton = ({base64Data, fileName})=> {
	const downloadFile = () => {
		// Convert Base64 to Blob
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'application/octet-stream' });

		// Create a download link
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;

		// Trigger the download
		document.body.appendChild(a);
		a.click();

		// Clean up
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	};

	return (
            <Button icon='pi pi-download' rounded outlined onClick={downloadFile} />
	);
}

export default DownloadFileButton;
