import LogoutButton from "./LogoutButton";
import { useAuthUser } from "src/hooks/useAuthUser";
import { Avatar } from 'primereact/avatar';

const UsuarioComponent = () =>{
    const { user } = useAuthUser();

    return (
			<ul class='p-0 m-0 list-none'>
				<li class='flex align-items-center py-2 border-bottom-1 surface-border'>
					<div class='w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0'>
						<Avatar label={user?.nombre?.substring(0, 1) ?? 'U'} size='large' shape='circle' 
							className='bg-blue-400 text-white'/>
					</div>
					<span class='text-900 line-height-3 font-medium'>
						{user?.nombre ?? 'NombreUser'}
						<br /> 
						<span class='text-700 font-normal'>{user?.email ?? 'usuario@usuario.com'}</span>
					</span>

					<LogoutButton large />
				</li>
			</ul>
			// <div style={{ width: '400px' }} className='grid text-white'>
			// 	<div className='col-2 p-0'>
			// 		<Avatar label={user?.nombre?.substring(0, 1) ?? 'U'} size='large' shape='circle' />
			// 	</div>
			// 	<div className='col-4 p-0'>
			// 		<div className='text-900 text-white  font-medium mb-1'>{user?.nombre}</div>
			// 		<div className='text-600 text-white text-xs'>{user?.email}</div>
			// 	</div>
			// 	<div className='col-1 p-0'>
			// 		<LogoutButton large />
			// 	</div>
			// </div>
		);

};

export default UsuarioComponent;