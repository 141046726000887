import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useCallback,  useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { sumaDias } from 'src/services/commonHelpers';

const SubeFicheroEmpresaDialog = ({datos, onClose, saveDocumentoEmpresa, setDatos}) =>{
    const [file, setFile] = useState();
	const [fileName, setFileName] = useState();
    const [files, setFiles]  = useState();


    const arrayBufferToBase64=(buffer) =>{
			// Create a Uint8Array view on the ArrayBuffer
			var binary = '';
			var bytes = new Uint8Array(buffer);

			for (var i = 0; i < bytes.byteLength; i++) {
				binary += String.fromCharCode(bytes[i]);
			}

			return btoa(binary);
		}
		
     const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = ev => {
                // Do whatever you want with the file contents
                const binaryStr = ev.target.result;
                const str = arrayBufferToBase64(binaryStr);
               setFile(str);
			   setFileName(file.name);
            };
            reader.readAsArrayBuffer(file);
        });
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
			onDrop,
			maxFiles: 1,
			accept: {
				'application/pdf': ['.pdf'],
				'image/jpeg': ['.jpeg'],
				'image/png': ['.png'],
			},
		});

    const saveFicheroEmpresaAction = useCallback(() => {
			if (!file || file?.length <= 0) {
				toast.error('ERROR NO hay fichero');
			} else {
				let pertenece = datos.perteneceTrabajador ?? datos.perteneceEmpresa;
				const doc = {
					id: datos.id,
					tipo: datos.tipo,
					nombre: files[0]?.name,
					documento: file,
					nombreFichero: fileName,
					fechaCreacion: new Date(),
					pertenece: pertenece??datos?.pertenece,
					fecha: datos.fecha,
					caducidad: datos.caducidad,
					descripcion: datos.descripcion
				};
				delete doc['datosTipo'];
				saveDocumentoEmpresa(doc);
                onClose();
			}
		}, [datos, file, files, saveDocumentoEmpresa,onClose,fileName]);

   const footerContent = (
			<div>
				<Button label='Cerrar' icon='pi pi-times' onClick={onClose} className='p-button-text' />
				<Button label='Guardar' icon='pi pi-check' onClick={saveFicheroEmpresaAction} autoFocus />
			</div>
		);
	const actualizaDatos = useCallback(
		(value, attr) => {
			const newDatos = { ...datos };
			newDatos[attr] = value;
			if (attr==='fecha' && datos?.datosTipo?.caducidad){
				newDatos['caducidad'] = sumaDias(value, datos?.datosTipo?.tiempoCaducidad);
			}
			setDatos(newDatos);
		},
		[datos, setDatos]
	);
		
    return (
			<Dialog
				header={`Subir documento ${datos?.tipoNombre}`}
				visible={true}
				style={{ width: '50vw' }}
				onHide={onClose}
				footer={footerContent}
			>
				<div {...getRootProps()} style={{ border: '1px dashed black', margin: 5, borderRadius: '5px' }}>
					<input {...getInputProps()} />
					<p style={{ margin: 0, padding: 0 }}>Arrastre algún fichero o haga click para buscar en su pc</p>
					{files?.map((file) => (
						<i>{file.name}</i>
					))}
				</div>
				<div>
					<strong>Fecha documento:</strong>
					<br />
					<Calendar
						value={datos?.fecha}
						className='w-full'
						onChange={(e) => actualizaDatos(e.value, 'fecha')}
						showIcon
						locale='es'
					/>
					<br />
					{datos?.datosTipo?.caducidad && <><strong>Fecha caducidad:</strong>
					<br />
					<Calendar
						value={datos?.caducidad}
						className='w-full'
						
						showIcon
						locale='es'
						onChange={(e) => actualizaDatos(e.value, 'caducidad')}
					/>
					<br />
					</>}
					<strong>Descripción:</strong>
					<br />
					<InputText
						value={datos?.descripcion}
						className='w-full'
						
						onChange={(e) => actualizaDatos(e.target.value, 'descripcion')}
					/>
					<br />
				</div>
			</Dialog>
		);
}

export default SubeFicheroEmpresaDialog;