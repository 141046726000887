import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {  useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuthUser } from 'src/hooks/useAuthUser';
import { usePersonasFetch } from 'src/hooks/usePersonasFetch';

const NuevaPersonaDlg = ({  onClose }) => {
	const [persona, setPersona] = useState({});	
  	const { user } = useAuthUser();

	const {savePersonaAction} = usePersonasFetch();

	const savePersonaButton = useCallback(()=>{
		if (persona?.nombre && persona?.apellidos && persona?.dni){
			const newP = {
				id:null,
				nombre: `${persona?.nombre} ${persona?.apellidos}`,
				dni: persona?.dni,
				proveedor: user?.id,
				autonomo: false,
				habilitado:true,
				entradaObra: 0
			};
			savePersonaAction(newP);
			onClose();
		}else{
			toast.error('Todos los campos son obligatorios');
		}
	},[onClose, persona?.apellidos, persona?.dni, persona?.nombre, savePersonaAction, user?.id]);

	const footerContent = (
		<div>
			<Button label='Cerrar' icon='pi pi-times' onClick={onClose} classNameName='p-button-text' />
			<Button label='Guardar' icon='pi pi-check' autoFocus onClick={savePersonaButton} />
		</div>
	);

	const onChangeVal = useCallback((attr, value)=>{
		const pp={...persona};
		pp[attr]=value;
		setPersona(pp);
	},[persona]);

	return (
		<Dialog header='Nueva persona ' visible={true} style={{ width: '25vw' }} onHide={onClose} footer={footerContent}>
			<div className='surface-section'>
				<ul className='list-none p-0 m-0'>
					<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-5 font-medium'>Nombre</div>
						<div className='text-900 w-7 flex-order-1'>
							<InputText value={persona?.nombre} onChange={(e) => onChangeVal('nombre', e.target.value)} />
						</div>
					</li>
					<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-5 font-medium'>Apellidos</div>
						<div className='text-900 w-7 flex-order-1'>
							<InputText value={persona?.apellidos} onChange={(e) => onChangeVal('apellidos', e.target.value)} />
						</div>
					</li>
					<li className='flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-5 font-medium'>DNI (sin guiones ni espacios)</div>
						<div className='text-900 w-7 flex-order-1'>
							<InputText
								value={persona?.dni}
								onChange={(e) => onChangeVal('dni', e.target.value)}
								keyfilter='alphanum'
							/>
						</div>
					</li>
				</ul>
			</div>
		</Dialog>
	);
};

export default NuevaPersonaDlg;
