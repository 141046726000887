import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import AppContext from "src/contexts/AppContext";
import { creaPersonaService, getPersonalEmpresaService } from "src/services/reactivaService";

export const usePersonasFetch = () => {
	const { user } = useContext(AppContext);
	const queryClient = useQueryClient();

	const { data: personal, isLoading: isLoadingPersonal } = useQuery({
		queryKey: ['personalEmpresa'],
		queryFn: async () => await getPersonalEmpresaService(user?.id),
		enabled: !!user?.id,
	});

	const { mutate: savePersonaAction } = useMutation({
		mutationFn: (doc) => creaPersonaService(doc),
		onSuccess: data => {
			if (!data.error){
				toast(data.mensaje);
				queryClient.invalidateQueries({ queryKey: ['personalEmpresa'], exact: false, refetchType: 'all' });
			}else{
				toast.error(data.mensaje);
			}
		},
		onError: (error) => {
			toast.error(`Error guardando persona ${error}`);
		},
	});


	return {
		personal,
		isLoadingPersonal,
		savePersonaAction
	};
};
