import {  useState } from "react";
import SubeFicheroEmpresaDialog from "src/components/docsEmpresa/SubeFicheroEmpresaDialog";
import AppLayout from "src/components/layout/AppLayout";
import { useDocumentosPedientesFetch } from "src/hooks/useDocumentosPedientesFetch";
import TablaDocumentos from "src/components/common/TablaDocumentos";
import TablaDocumentosPendientes from "src/components/common/TablaDocumentosPendientes";
import { useMemo } from "react";
import { BlockUI } from "primereact/blockui";
import { useTiposDocFetch } from "src/hooks/useTiposDocFetch";
import { useAuthUser } from "src/hooks/useAuthUser";
const DocumentacionPage = ()=>{
	const { tipoDocs } = useTiposDocFetch();
	const [openedUpload, setOpenedUpload] = useState(null);
	const {  docsPendientesValidar, uploading, saveDocumentoEmpresa } = useDocumentosPedientesFetch();
	const {user} = useAuthUser();
	
	const tiposEmpresa = useMemo(() =>
		 tipoDocs?.filter((t) => t.pertenece === 'PROVEEDOR')
		?.filter(t=>user?.autonomo ? t.obligatorioAutonomo: true)
	?.map(t=>t.id), [tipoDocs, user?.autonomo]);


	const docsPendientesEmpresa = useMemo(
		() => docsPendientesValidar?.filter((d) =>tiposEmpresa?.includes(d?.tipo)),
		[docsPendientesValidar, tiposEmpresa]
	);

    return (
			<AppLayout>
				<div class='surface-section px-2 py-2'>
					<div class='flex align-items-start flex-column lg:justify-content-between lg:flex-row'>
						<div>
							<div class='font-medium text-3xl text-900'>Documentación</div>
							<div class='flex align-items-center text-700 flex-wrap'>
								{/* <div class='mr-5 flex align-items-center mt-3'>
									<i class='pi pi-file-pdf mr-2'></i>
									<span>{docsPendientes?.length} Total documentos</span>
								</div> */}
								{/* <div class='mr-5 flex align-items-center mt-3'>
									<i class='pi pi-clock mr-2'></i>
									<span>{docsPendientesEmpresa?.length} pendientes validación</span>
								</div> */}
							</div>
						</div>
					</div>
					<div>
						<h5> Documentos proveedor</h5>
						<TablaDocumentos
							documentosValidar={docsPendientesEmpresa}
							openedUpload={openedUpload}
							setOpenedUpload={setOpenedUpload}
							habilitado
						/>
						<h5> Documentos de empresa en validación</h5>
						<TablaDocumentosPendientes docsPendientes={docsPendientesEmpresa} setOpenedUpload={setOpenedUpload} 
							habilitado
						/>
					</div>
				</div>
				{openedUpload && (
					<SubeFicheroEmpresaDialog
						setDatos={setOpenedUpload}
						datos={openedUpload}
						onClose={() => setOpenedUpload(false)}
						saveDocumentoEmpresa={saveDocumentoEmpresa}
					/>
				)}
				<BlockUI blocked={uploading} fullScreen />
			</AppLayout>
		);

};

export default DocumentacionPage;
