import { useQuery } from '@tanstack/react-query';
import { getDocumentosTrabajadorService } from 'src/services/reactivaService';
export const useDocumentosTrabajador = ({id} = {}) => {
	const { data: documentosTrabajador, isLoading: isLoadingDocs } = useQuery({
		queryKey: ['documentosTrabajador', id],
		queryFn: async () => await getDocumentosTrabajadorService(id),
		enabled: !!id,
	});

	return { documentosTrabajador, isLoadingDocs };
};
