import { useQueryClient } from "@tanstack/react-query";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { useCallback, useState } from "react";
import { useMemo } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import TablaDocumentos from "src/components/common/TablaDocumentos";
import TablaDocumentosPendientes from "src/components/common/TablaDocumentosPendientes";
import SubeFicheroEmpresaDialog from "src/components/docsEmpresa/SubeFicheroEmpresaDialog";
import AppLayout from "src/components/layout/AppLayout";
import { useDocumentosPedientesFetch } from "src/hooks/useDocumentosPedientesFetch";
import { usePersonasFetch } from "src/hooks/usePersonasFetch";
import { creaPersonaService } from "src/services/reactivaService";

const PersonaPage = ()=>{
    const { id } = useParams();		
	const queryClient = useQueryClient();

	const [openedUpload, setOpenedUpload] = useState(null);

	const { personal } = usePersonasFetch();
	const { docsPedientesTrabajadores, docsPendientesValidarTrabajador, 
		saveDocumentoEmpresa, uploading } =
		useDocumentosPedientesFetch({idTrabajador:id});

    const persona= useMemo(()=>(
        personal?.find(p=>String(p.id)===id)
    ),[personal, id]);
	
	const documentosTrabajador = useMemo(() => docsPedientesTrabajadores?.filter((d) =>
		String(d.pertenecePersona?.id)===id),[docsPedientesTrabajadores, id]);

	const activrDesactivar = useCallback(async ()=>{
		const resp = await creaPersonaService({...persona, habilitado: !persona.habilitado});
		if (!resp.error) {
			queryClient.invalidateQueries({ queryKey: ['personalEmpresa'] });
			toast.success('Datos guardados correctamente');
		} else {
			toast.error('Error guardando datos');
		}
	},[persona, queryClient]);	

    return (
			<AppLayout>
				{persona && (
					<>
						<div class='align-content-center w-full lg:w-3'></div>
						<div class='align-content-center text-center surface-card p-4 shadow-2 border-round w-full lg:w-12'>
							<div class='font-medium text-3xl text-900 mb-3'>
								 {persona?.nombre} {persona?.dni}
								<Button
									label={persona?.habilitado ? 'Desactivar' : 'Activar'}
									className='ml-2'
									onClick={activrDesactivar}
									
								/>
							</div>
							<TablaDocumentos
								documentos={documentosTrabajador}
								personal={id}
								documentosValidar={docsPendientesValidarTrabajador}
								setOpenedUpload={setOpenedUpload}
								habilitado={persona?.habilitado}
							/>
							<h5> Documentos de trabajadores en validación</h5>
							<TablaDocumentosPendientes
								docsPendientes={docsPendientesValidarTrabajador}
								setOpenedUpload={setOpenedUpload}
								habilitado={persona?.habilitado}
							/>
						</div>

						{openedUpload && (
							<SubeFicheroEmpresaDialog
								datos={openedUpload}
								onClose={() => setOpenedUpload(false)}
								saveDocumentoEmpresa={saveDocumentoEmpresa}
								setDatos={setOpenedUpload}
							/>
						)}
						<BlockUI blocked={uploading} fullScreen />
					</>
				)}
				{!persona && <div> No existe el trabajador</div>}
			</AppLayout>
		);
};

export default PersonaPage;