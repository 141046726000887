import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import AppContext from 'src/contexts/AppContext';
import { getDocumentosProveedorService } from 'src/services/reactivaService';

export const useDocumentosProveedor = () => {
    
     const { user } = useContext(AppContext);
	const { data: documentos, isLoading: isLoadingDocs } = useQuery({
		queryKey: ['documentos'],
		queryFn: async () => await getDocumentosProveedorService(user?.id),
	});

	return { documentos, isLoadingDocs };
};
