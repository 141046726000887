import { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AppContext from 'src/contexts/AppContext';
import { getDocsPendientesService, getDocsPendientesTrabajadoresService, getDocsPendientesValidarService
	, getDocsPendientesValidarTrabajadorService, subeDocumentoEmpresaService } from 'src/services/reactivaService';
import { toast } from 'react-toastify';

export const useDocumentosPedientesFetch = ({idTrabajador}={}) => {
     const { user } = useContext(AppContext);
	const [uploading, setUploading]=useState(null);
	 const queryClient = useQueryClient();

	const { data: docsPendientes, isLoading: isLoadingDocsPendientes } = useQuery({
		queryKey: ['docsPendientes'],
		queryFn: async () => await getDocsPendientesService(user?.id),
		enabled: !!user?.id,
	});


	const { data: docsPedientesTrabajadores, isLoading: isLoadingDocsPendientesTrabajadores } = useQuery({
		queryKey: ['docsPendientesTrabajadores'],
		queryFn: async () => await getDocsPendientesTrabajadoresService(user?.id),
		enabled: !!user?.id,
	});

	const { data: docsPendientesValidar, isLoading: isLoadingDocsPendientesValidar } = useQuery({
		queryKey: ['docsPendientesValidar'],
		queryFn: async () => await getDocsPendientesValidarService(user?.id),
		enabled: !!user?.id
	});

	const { data: docsPendientesValidarTrabajador, isLoading: isLoadingDocsPendientesValidarTrabajador } = useQuery({
		queryKey: ['docsPendientesValidarTrabajador',idTrabajador],
		queryFn: async () => await getDocsPendientesValidarTrabajadorService(idTrabajador),
		enabled: !!idTrabajador
	});

	const { mutate: saveDocumentoEmpresa } = useMutation({
		mutationFn: (doc) => subeDocumentoEmpresaService(doc),
		onMutate: () => {
			setUploading(true);
		},
		onSuccess: data => {
			if (!data.error){
				setUploading(null);
				toast('Documento guardado correctamente');
				queryClient.invalidateQueries({ queryKey: ['docsPendientesValidarTrabajador'], exact: false, refetchType: 'all' });
				queryClient.invalidateQueries({ queryKey: ['docsPendientesValidar'], exact: false, refetchType: 'all' });
				queryClient.invalidateQueries({ queryKey: ['docsPendientes'], exact: false, refetchType: 'all' });
				queryClient.invalidateQueries({ queryKey: ['documentos'], exact: false, refetchType: 'all' });
				queryClient.invalidateQueries({ queryKey: ['documentosTrabajador'], exact: false, refetchType: 'all' });
				
			}else{
				setUploading(null);
				toast.error('Error subiendo documento');
			}
		},
		onError: (error) => {
			setUploading(null);
			toast.error(`Error guardando documento ${error}`);
		},
	});

	return { docsPendientes, isLoadingDocsPendientes, 
		docsPedientesTrabajadores , isLoadingDocsPendientesTrabajadores,
		docsPendientesValidar, isLoadingDocsPendientesValidar,
		docsPendientesValidarTrabajador, isLoadingDocsPendientesValidarTrabajador,
		uploading,
		saveDocumentoEmpresa};
};
