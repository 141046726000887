import { Menubar } from "primereact/menubar";
import PropTypes from 'prop-types';
import UsuarioComponent from "../auth/UsuarioComponent";

import reactiva from 'src/assets/reactiva.png';
import { useCallback } from "react";
import { useNavigate } from "react-router";

const AppLayout = ({children})=>{
	const navigate = useNavigate();

    const goTo = useCallback(
			(url) => {
				navigate(url);
			},
			[navigate]
		);


	const items = [
		{
			label: 'Home',
			icon: 'pi pi-home',
			command: () => {
				goTo('/');
			},
			className: 'text-white-alpha-10',
		},
		{
			label: `Trabajadores`,
			icon: 'pi pi-user',
			style: { color: 'white' },
			command: () => {
				goTo('/personal');
			},
		},
		{
			label: `Documentación `,
			icon: 'pi pi-wallet',
			style: { color: 'white' },
			command: () => {
				goTo('/documentacion');
			},
			//	visible: user?.tipoUsuario === 'RESPONSABLE' || user?.tipoUsuario === 'ADMINISTRADOR',
			//     command: ()=>{ goTo('/recetas/DESAYUNO')}
		},
	];

    return (
			<div className='surface-0 relative '>
				<Menubar
					start={
						<img
							alt='logo'
							src={reactiva}
							height='50'
							style={{
								borderRadius: '8px',
								padding: '3px',
								backgroundColor: 'white',
								boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
							}}
						/>
					}
					end={<UsuarioComponent />}
					model={items}
					className='bg-indigo-200 text-white-alpha-10 p-0'
				/>

				<div
					className='surface-ground flex-column flex-auto'
					style={{
						maxHeight: 'calc(100vh - 100px)',
						minHeight: 'calc(100vh - 100px)',
						overflowY: 'auto',
						overflowX: 'hidden',
					}}
				>
					{children}
				</div>
			</div>
		);
}

AppLayout.propTypes={
    children: PropTypes.any
}

export default AppLayout ;