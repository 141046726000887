import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import AppContext from 'src/contexts/AppContext';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { refreshTokenService } from 'src/services/reactivaService';
import InfoComponent from '../InfoComponent';

const RequireAuth = ({ children,roles }) => {
	const { setItem } = useLocalStorage();
	const { setUser,user } = useContext(AppContext);


	const isValidUser = useMemo(() => {
		if (user?.token && user?.cif) {
			let decodedToken = jwtDecode(user?.token);
			let currentDate = new Date();
			// JWT exp is in seconds
			if (decodedToken.exp * 1000 < currentDate.getTime()) {
				axios.defaults.headers.common = null;
				return false;
			} else {
				axios.defaults.headers.common = { Authorization: `${user?.token}` };
				return true;
			}
		}
		return false;
	}, [user]);

	const intervalRef = useRef();

	const getToken = useCallback(() =>{
		refreshTokenService(user?.cif).then(data =>{
			setItem('user', JSON.stringify(data));
			setUser(data);
		});
	} , [setItem, setUser, user]);

	useEffect(() => {
		if (isValidUser){
			const interval = setInterval(() => getToken(), 3*60*1000);
			intervalRef.current = interval;
			return () => clearInterval(interval);
		}
	}, [getToken, isValidUser]);

	if (user && user?.tipoUsuario === 'TRABAJADOR') {
		return <InfoComponent title='Error' message='NO puede acceder a esta aplicación' error logoutB/>;
	}

	if (user && roles?.length && !roles?.includes(user?.tipoUsuario)) {
		return <InfoComponent title='Error' message='NO puede acceder a esta sección' error logoutB />;
	}

	if (!isValidUser) {
		return <Navigate to='/login' replace />;
	}
	
	return user ? children : <Navigate to='/login' replace />;
};

RequireAuth.propTypes = {
	children: PropTypes.any,
	roles: PropTypes.array
};
export default RequireAuth;
