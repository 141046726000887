import {  useQuery } from '@tanstack/react-query';
import { getTiposDocumentosService } from 'src/services/reactivaService';

export const useTiposDocFetch = () => {
	const { data: tipoDocs, isLoading: isLoadingTipoDocs } = useQuery({
		queryKey: ['tipoDocs'],
		queryFn: async () => await getTiposDocumentosService(),
	});

	return { tipoDocs, isLoadingTipoDocs };
};