
import { Button } from 'primereact/button';
import { useAuthUser } from 'src/hooks/useAuthUser';
import PropTypes from 'prop-types';

const LogoutButton = ({large}) => {
	const { logout } = useAuthUser();

	return (
		<Button
			icon='pi pi-power-off'
			rounded
			
		//	outlined
		//	severity='secondary'
			size={large ? 'large ' : 'small'}
			aria-label='Bookmark'
			onClick={logout}
		/>
	);
};

LogoutButton.propTypes={
	large: PropTypes.bool
}
export default LogoutButton;
