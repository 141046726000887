

import RequireAuth from './components/auth/RequireAuth';
import { useAuthUser } from './hooks/useAuthUser';
import AlertasPage from './pages/AlertasPage';
import DocumentacionPage from './pages/DocumentacionPage';
import LoginPage from './pages/LoginPage';
import { Route, Routes } from 'react-router';
import PersonalPage from './pages/PersonalPage';
import PersonaPage from './pages/PersonaPage';


const Main = ()=> { 
	useAuthUser();

     return (
				<Routes>
					<Route exact path='/login' element={<LoginPage />} />
					<Route
						exact
						path='/'
						element={
							<RequireAuth>
								<AlertasPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/documentacion'
						element={
							<RequireAuth>
								<DocumentacionPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/personal'
						element={
							<RequireAuth>
								<PersonalPage />
							</RequireAuth>
						}
					/>
					<Route
						exact
						path='/persona/:id'
						element={
							<RequireAuth>
								<PersonaPage />
							</RequireAuth>
						}
					/>
				</Routes>
			);
}

export default Main;